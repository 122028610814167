.input-general-form .label {
  font-size: 11px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 12px;
  top: 6.5px;
  padding: 0 5px;
  transition: 0.3s ease all;
  /* background-color: #f5f5f5; */
  z-index: 1;
}

.input-general-form .animate-label {
  top: -9px;
  left: 12px;
  font-size: 10px;
  z-index: 1;
}

.wrapper-fields {
  margin-top: 10px;
  margin-bottom: 10px;
  position: relative;
  max-width: 100%;
}

.input-general-form .ant-input {
  font: inherit;
  letter-spacing: inherit;
  color: currentcolor;
  border: 0px;
  box-sizing: content-box;
  border-bottom: solid 1px #000;
  background: transparent;
  height: 1.4375em;
  margin: 0px;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 0px;
  max-width: 100%;
}

.input-general-form .ant-input-affix-wrapper {
  padding: 4px 4px;
  border-radius: 5px;
}

.input-general-form .ant-input-outlined,
.input-general-form .ant-input-outlined:hover {
  background: transparent !important;
  border-width: 1px;
  border-style: solid;
  border-color: #d9d9d9;
}

.input-general-form .ant-picker.ant-picker-outlined,
.input-general-form .ant-picker-outlined.ant-picker:hover {
  background-color: transparent;
}

.input-general-form .field-error-color {
  color: #f44336;
  font-weight: 400;
  font-size: 0.75rem;
  text-align: left;
  margin: 0px 0px -17px 17px;
}

.input-general-form .label-error-color {
  color: #f44336;
}

.input-general-form .no-field-error {
  display: none;
}

.input-general-form .ant-select-multiple.ant-select .ant-select-selection-item {
  height: 22px;
  margin-left: 12px;
  line-height: 21px;
  display: flex;
  align-items: center;
}

.input-general-form
  .ant-select-single.ant-select-lg.ant-select-show-arrow
  .ant-select-selection-item,
.input-general-form
  .ant-select-single.ant-select-lg.ant-select-show-arrow
  .ant-select-selection-placeholder {
  margin-left: 10px;
}

.input-general-form
  .ant-select-multiple.ant-select-lg
  .ant-select-selection-overflow {
  padding: 5px 0 5px 0;
}

.input-general-form .ant-picker .ant-picker-input {
  margin-left: 5px;
}

.input-general-form .ant-input-outlined:focus-within {
  box-shadow: 0 0 0 0 rgba(3, 20, 22, 0.48);
}

.input-general-form .ant-select-single,
.input-general-form .ant-select-multiple,
.input-general-form .ant-picker-outlined,
.input-general-form .ant-picker .ant-picker-outlined {
  min-height: 30px;
  width: 100%;
}
.input-general-form
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item {
  font-size: 12px;
}
.input-general-form
  .ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector,
.input-general-form .ant-select-multiple {
  /* height: 30px; */
}
.ant-select-multiple
  .ant-select-selection-overflow
  .ant-select-selection-item-remove {
  color: inherit;
}

.input-general-form
  .ant-input-outlined.ant-input-status-error:not(.ant-input-disabled) {
  background-color: #f5f5f5;
}

.input-general-form
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-item,
.input-general-form
  .ant-select-single
  .ant-select-selector
  .ant-select-selection-placeholder {
  padding-left: 5px !important;
}

.input-general-form .ant-picker-outlined:focus,
.input-general-form .ant-picker-outlined:focus-within {
  box-shadow: 0 0 0 0 rgba(3, 20, 22, 0.48);
}

.input-general-form .ant-input-affix-wrapper > input.ant-input {
  padding-left: 2px;
  padding-top: 1px;
}

.input-general-form .ant-select-selection-search-input {
  margin-left: 5px !important;
}

.input-general-form .ant-select-selector {
  padding-top: 8px;
  padding-bottom: 10px;
}
.ant-select-outlined:not(.ant-select-customize-input) .ant-select-selector {
  border: unset !important;
  background: transparent !important;
  box-shadow: none;
}
.ant-select-focused.ant-select-outlined:not(.ant-select-disabled):not(
    .ant-select-customize-input
  ):not(.ant-pagination-size-changer)
  .ant-select-selector {
  border-color: unset !important;
  box-shadow: 0 0 0 0 !important;
  outline: 0;
}
.ant-picker .ant-picker-input-placeholder > input {
  color: inherit !important;
}
.ant-select-selector {
  color: inherit !important;
}
.ant-select-selection-item {
  color: inherit !important;
}
.ant-input-outlined:hover,
.ant-input-outlined:focus {
  border-color: inherit;
}
.ant-select
  .ant-select-outlined
  .ant-select-multiple
  .ant-select-show-arrow
  .ant-select-show-search {
  padding-top: 4px;
  padding-bottom: 0px;
}
.ant-select-multiple {
  font-size: 10px;
}

.ant-select-multiple .ant-select-selector {
  padding-top: 2px;
  padding-bottom: 0px;
}
.ant-select-multiple .ant-select-selection-overflow .ant-select-selection-item {
  margin-inline-end: -5px;
  padding-inline-start: 3px;
  padding-inline-end: 3px;
}
.ant-select-dropdown .ant-select-item {
  font-size: 12px;
  padding: 5px 0px 0px 5px;
}
.ant-picker .ant-picker-input > input {
  font-size: 12px;
}
.ant-picker-dropdown {
  font-size: 11px;
}
