.custom-ok-button {
  font-size: 12px;
  font-family: inter-reg;
}

.custom-cancel-button {
  font-size: 12px;
  font-family: inter-reg;
}
.custom-popover.dark-theme .ant-popover-arrow::before {
  background-color: #121212 !important;
}
