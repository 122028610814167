/* .sidebarWrapperCustom .css-1wvake5 {
  background-color: white;
  border: white;
} */
/* .sidebarWrapperCustom .css-1wvake5.ps-collapsed {
    width: 118px;
    min-width: 118px;
  } */
/* .sidebarWrapperCustom {
  background-color: #192c71;
} */
.light-sidebarWrapperCustom .css-um1o6k {
  padding: 5px;
}
.light-sidebarWrapperCustom .css-1cuxlhl {
  padding: 20px;
}
.light-sidebarWrapperCustom .css-z5rm24 {
  background-color: #f7f7fa;
}
.light-sidebarWrapperCustom .css-edu7i6 {
  background-color: #f7f7fa;
}
.light-sidebarWrapperCustom .css-uyo6hb {
  background-color: #f7f7fa;
  padding-bottom: 11px;
}
.light-sidebarWrapperCustom .css-12w9als {
  padding-left: 1px;
}
.light-sidebarWrapperCustom .css-1jp6z4y {
  background-color: #f7f7fa;
  margin-left: 10px !important;
  /* width: 300px !important; */
}
.light-sidebarWrapperCustom .css-1604tlu {
  border: 1px solid #f7f7fa;
}
/* ///////////////////////////////// */

.dark-sidebarWrapperCustom .css-um1o6k {
  padding: 5px;
}
.dark-sidebarWrapperCustom .css-1cuxlhl {
  padding: 20px;
}
.dark-sidebarWrapperCustom .css-z5rm24 {
  background-color: #121212;
}
.dark-sidebarWrapperCustom .css-edu7i6 {
  background-color: #121212 !important;
}
.dark-sidebarWrapperCustom .css-uyo6hb {
  background-color: #121212;
  padding-bottom: 11px;
}
.dark-sidebarWrapperCustom .css-12w9als {
  padding-left: 1px;
}
.dark-sidebarWrapperCustom .css-1jp6z4y {
  background-color: #1f1f1f;
  margin-left: 10px !important;
}
.dark-sidebarWrapperCustom .css-1604tlu {
  border: 1px solid #1f1f1f;
}
/* @media only screen and (max-width: 1023px) {
  .sidebarWrapperCustom {
    position: fixed;
    top: 0;
    z-index: 1;
  }
} */
/* .dark-sidebarWrapperCustom .css-dip3t8 {
  background-color: #192c71;
} */
/* .sidebarWrapperCustom .css-ewdv3l {
  padding-top: 14px;
  padding-bottom: 14px;
} */
/* .sidebarWrapperCustom .ps-menu-button:hover {
  background-color: #2d4dc2 !important;
  color: white;
} */

/* .menuItemActive {
    background-color: #03171f !important;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
    color: white;
  } */

/* .menu2{
    background-image: url("/public/");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: left;
  }
  
  
  .menu2:hover{
    background-image: url("/public/images/icons/dashbRed.svg");
    background-repeat: no-repeat;
    background-size: 20px;
    background-position: left;
  } */

.logo-menu-item:hover {
  background-color: white;
}

#root
  > div
  > div
  > div
  > aside
  > div
  > nav
  > ul
  > li
  > a:hover
  #root
  > div
  > div
  > div
  > aside
  > div
  > nav
  > ul
  > li
  > a
  > span.ps-menu-icon.css-wx7wi4
  > img {
  display: none;
}

/* .sidebarWrapperCustom .css-ewdv3l {
  padding-top: 14px;
  background-color: #192c71;
} */
