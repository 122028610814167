@tailwind base;
@tailwind components;

h1 {
  @apply text-4xl;
}
h2 {
  @apply text-3xl;
}
h3 {
  @apply text-2xl;
}
h4 {
  @apply text-xl;
}
h5 {
  @apply text-lg;
}
h6 {
  @apply text-base;
}

@tailwind utilities;

/* Thin 100 */
@font-face {
  font-family: 'inter-thin';
  src: local('inter'),
    url('../src/fonts/inter/Inter-Thin.ttf') format('truetype');
}
/* Light 300 */
@font-face {
  font-family: 'inter-light';
  src: local('inter'),
    url('../src/fonts/inter/Inter-Light.ttf') format('truetype');
}

/* ExtraLight 200 */
@font-face {
  font-family: 'inter-extraLight';
  src: local('inter'),
    url('../src/fonts/inter/Inter-ExtraLight.ttf') format('truetype');
}
/* Regular 400 */
@font-face {
  font-family: 'inter-reg';
  src: local('inter'),
    url('../src/fonts/inter/Inter-Regular.ttf') format('truetype');
}
/* Medium 500 */
@font-face {
  font-family: 'inter-med';
  src: local('inter'),
    url('../src/fonts/inter/Inter-Medium.ttf') format('truetype');
}
/* SemiBold 600 */
@font-face {
  font-family: 'inter-semiBold';
  src: local('inter'),
    url('../src/fonts/inter/Inter-SemiBold.ttf') format('truetype');
}

/* Bold 700 */
@font-face {
  font-family: 'inter-bold';
  src: local('inter'),
    url('../src/fonts/inter/Inter-Bold.ttf') format('truetype');
}

::-webkit-scrollbar {
  display: none;
}
.show-scroll::-webkit-scrollbar {
  width: 5px;
  display: block;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8; /* color of the scrollbar handle on hover */
  cursor: row-resize !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #a8a8a8; /* color of the scrollbar handle */
  border-radius: 20px; /* roundness of the scrollbar handle */
}

/* Track */
::-webkit-scrollbar-track {
  background: #f5f5f5; /* color of the scrollbar track */
}

/* Corner */
::-webkit-scrollbar-corner {
  background: #f1f1f1; /* color of the scrollbar corner (where horizontal and vertical scrollbars meet) */
}
.light-theme.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more {
  border-bottom: 1px solid #f0f0f0;
}
.dark-theme .ant-tabs > .ant-tabs-nav .ant-tabs-nav-more {
  border-bottom: 1px solid #353535;
}
.light-theme .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  width: 10px;
  border-bottom: 1px solid #f0f0f0;
}
.dark-theme .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  width: 10px;
  border-bottom: 1px solid #353535;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more {
  padding: 8px 8px;
}

.custom-dropdown-bg .ant-tabs-dropdown .ant-tabs-dropdown-menu-item {
  background-color: aqua;
}
.wrapper-fields.ant-select-dropdown {
  background-color: #1f1f1f !important;
}

/* For unchecked state */
.dark-checkbox .ant-checkbox-inner {
  background-color: #111111;
}
.dark-mode .ant-table-row-expand-icon {
  background-color: #121212 !important;
  color: #ebe7e5 !important;
}

.ant-tabs .ant-tabs-tab .ant-tabs-tab-btn {
  font-size: 13px;
}

.nav-light-mode.ant-dropdown .ant-dropdown-menu-item-selected {
  background-color: #ebe7e5;
  color: #1f1f1f;
}
.nav-light-mode.ant-dropdown .ant-dropdown-menu-item-selected:hover {
  background-color: #0000000a;
}
.dark-theme .ant-tabs-card .ant-tabs-nav .ant-tabs-tab {
  border-color: #353535;
}
.light-theme .ant-tabs-card .ant-tabs-nav .ant-tabs-tab {
  border-color: #f0f0f0;
  background-color: #f7f7fa;
}
.dark-theme .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
  background-color: #434343;
}
.light-theme .ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active {
  background-color: #ffffff;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 500 !important;
}
.dark-theme .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: #434343;
}
.light-theme .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: #ffffff;
}
.ant-tabs-card > .ant-tabs-nav .ant-tabs-ink-bar {
  visibility: visible;
  height: 1px;
}
.secret-key-tag .ant-tag {
  white-space: unset;
}
.popover-dark-mode.ant-popover .ant-popover-arrow::before {
  background-color: #121212 !important;
}

.transition-div {
  overflow: hidden;
  transition: height 0.5s ease;
}

.transition-div.open {
  height: 320px;
}

.transition-div.closed {
  height: 0;
}
.ps-submenu-expand-icon {
  padding: 10px 20px;
}
.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  background-image: none !important;
  height: 0px;
}
@keyframes rotate360 {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(-360deg);
  }
}

.rotate {
  animation: rotate360 1.5s linear infinite;
}
