.custom-upload .ant-tooltip {
  display: none !important;
}

.custom-upload .ant-upload {
  width: 150px !important;
  height: 150px !important;
}

.ant-upload-list-item {
  width: 100% !important;
  height: 100% !important;
}

.ant-upload-list-item-container {
  width: 100% !important;
  height: 100% !important;
}

.ant-upload-list-item-thumbnail {
  width: 150px !important;
  height: 150px !important;
  object-fit: cover !important;
}
.picture-card-upload .custom-upload .ant-upload {
  width: 100% !important;
  height: 120px !important;
}
.picture-card-upload .ant-upload-list-item-thumbnail {
  width: 100% !important;
  height: 100px !important;
}
.ant-upload-wrapper.ant-upload-picture-card-wrapper
  .ant-upload-list.ant-upload-list-picture-card
  .ant-upload-list-item-thumbnail
  img {
  object-fit: cover !important;
}

.ant-upload-wrapper
  .ant-upload-list.ant-upload-list-picture-circle
  .ant-upload-list-item {
  padding: 6px !important;
}
